@import url("https://fonts.googleapis.com/css?family=Anaheim|Quattrocento+Sans:400,700");
@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700");

html {
  width: 100%;
  background-color: var(--main-bg-color);
}

body {
  background-color: var(--main-bg-color);
  font-family: "Quattrocento Sans", sans-serif;
  color: var(--main-color-white);
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: "Ubuntu", sans-serif;
  color: var(--main-color-white);
  hyphens: auto;
}

p {
  font-family: "Quattrocento Sans", sans-serif;
}

[scroll-behavior="smooth"] {
  scroll-behavior: smooth;
}

/*=================================================================
  Color Setup
==================================================================*/

:root {
  --main-color-1: #a9f7e3;
  --main-color-2: #61bdaa;
  --main-bg-color: #4c4c4c;
  --main-bg-color-dark: #3f3d3d;
  --main-color-black: #000;
  --main-color-white: #fff;
  --question-bar-color: #858585;
  --timer-progress-width: 100%;
}

/*=================================================================
  Basic Setup
==================================================================*/

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: var(--main-color-2);
}

::selection {
  color: #fff;
  background: var(--main-color-2);
}

a {
  color: var(--main-color-2);
  transition: all 0.3s ease-in 0s;
}

iframe {
  border: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus,
a:hover {
  color: var(--main-color-1);
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.loading-results {
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.btn {
  background-color: transparent;
  color: #fff;
  padding: 10px 30px;
  border-radius: 0;
  transition: all 0.3s ease-in 0s;
}

.btn:focus {
  color: #ddd;
}

.btn-transparent {
  border-color: var(--main-color-2);
  font-family: "Ubuntu", sans-serif;
  padding: 12px 30px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  border-radius: 8px;
  border-width: 2px;
}

.btn-startseite {
  padding: 12px 26px;
  margin-top: 1em;
}

.btn-prüfung {
  margin-left: 30em;
  margin-top: 2em;
}

.btn-modal {
  margin-top: 5em;
  float: right;
}

.btn-transparent:hover,
.btn-transparent:focus {
  background-color: var(--main-color-2);
  border: 2px solid transparent;
  color: #fff;
}

.headline-border {
  border-top: 2px solid var(--main-color-2);
  height: 1px;
  margin: 0.2em auto 0;
  position: relative;
  width: 25%;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
}

.bg-one {
  background-color: var(--main-bg-color);
}

.bg-darker {
  background: var(--main-bg-color-dark);
}

.inline-block {
  display: inline-block;
}

figure {
  margin: 0;
}

a {
  transition: all 0.2s ease-in 0s;
}

iframe {
  border: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus,
a:hover {
  color: var(--main-color-1);
}

.btn-main {
  border-color: var(--main-color-2);
  font-family: "Ubuntu", sans-serif;
  padding: 14px 36px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  border-radius: 8px;
  border-width: 2px;
}

.btn-main:hover {
  background: var(--main-color-2);
  color: #fff;
}

section {
  padding: 100px 0;
}

@media (max-width: 480px) {
  section {
    padding: 50px 0;
  }
}

@media (max-width: 768px) {
  section {
    padding: 80px 0;
  }
}

.not-found img {
  height: 450px;
  margin-bottom: 3em;
}

.section-sm {
  padding: 70px 0;
}

.btn:focus {
  color: #ddd;
  box-shadow: none;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
}

.title {
  padding-bottom: 60px;
}

.title h2 {
  font-weight: 700;
  font-size: 48px;
  color: #fff;
  text-align: center;
}

@media (max-width: 374px) {
  .title h2 {
    font-size: 44px;
  }
}

@media (max-width: 767px) {
  .title h2 {
    font-size: 40px;
  }
}

.title h2 span {
  color: var(--main-color-2);
}

.title p {
  color: #666;
}

.section-bg {
  background: var(--main-bg-color-dark);
  padding: 100px 0;
}

.overly {
  position: relative;
}

.overly:before {
  content: "";
  background: rgba(0, 0, 0, 0.096);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.logo {
  margin-top: 10px;
  display: inline-block;
}

/*=================================================================
  Footer section
==================================================================*/

.bg-black {
  background-color: var(--main-color-black);
}

.copyright {
  padding: 20px 0;
  color: #fff;
}

.copyright img {
  margin-top: 2em;
  height: 4em;
  margin-bottom: 3em;
}

.copyright p {
  margin-top: 10px;
  font-size: 16px;
  text-transform: none;
  color: var(--main-color-white);
}

.copyright a {
  font-size: 20px;
  font-family: "Ubuntu", sans-serif;
  text-transform: uppercase;
  padding: 0 1em;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.copyright a:hover {
  color: var(--main-color-2);
}

.ftext {
  margin-bottom: 4em;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .post-single-content {
    padding-left: 10px;
    padding-right: 10px;
  }

  .post-single-content p {
    width: 100%;
  }
}

.post-single-content {
  font-size: 16px;
  color: white;
  margin-top: 2em;
}

.post-single-content h2 {
  margin-bottom: 1em;
}

.navigation-shadow {
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
}

header {
  background-color: var(--main-bg-color-dark);
  width: 100%;
  margin-bottom: 0;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
  position: sticky;
  top: 0;
  padding: 10px;
  z-index: 100;
}

header .admin-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

header .admin-nav .admin-btn {
  margin-left: auto;
}

header .logo {
  vertical-align: middle;
  border-style: none;
  height: 3em;
}

/*=================================================================
  Logout Button
==================================================================*/
header .right-btn {
  padding-left: 0;
  flex-direction: row;
  margin-left: auto;
}

header .right-btn .logout {
  height: 50px;
}

header .right-btn .logout img {
  height: 25px;
  margin-left: 1em;
}

/*=================================================================
  Training Selection Boxes
==================================================================*/

.training-selection {
  background-color: var(--main-bg-color-dark);
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
  padding: 16px;
  margin-bottom: 2em;
  border-radius: 4px;
}

.training-selection img {
  margin-bottom: 1em;
}

.training-selection form .key {
  margin-bottom: 2em;
}

.training-selection form button {
  color: var(--main-color-white);
  margin-bottom: 2em;
}

.training-selection form button:disabled {
  border: 2px solid var(--main-color-black);
  color: var(--main-color-black);
  cursor: not-allowed;
}

.training-selection form button:disabled:hover {
  border: 2px solid var(--main-color-black);
  background-color: transparent;
  color: var(--main-color-black);
}

.training-selection form input {
  margin-top: 1em;
  padding: 15px 15px 15px 20px;
  border-radius: 8px;
  border: 1px solid var(--main-color-2);
  background-color: var(--main-bg-color);
  color: var(--main-color-white);
  height: 40px;
  width: 100%;
  outline: none;
}

.training-selection form input:placeholder-shown {
  border-color: var(--main-color-white);
}

.training-selection form input:required:valid {
  border-color: var(--main-color-2);
}

.training-selection form input:required:valid:focus {
  border-color: var(--main-color-2);
}

.training-selection form input:not(:placeholder-shown):invalid {
  border: 1px solid #f44f29;
  background-color: rgba(244, 79, 41, 0.15);
  box-shadow: none;
  outline: none;
}

.training-selection form input:focus {
  box-shadow: 1px 1px 30px var(--main-bg-color-dark);
  border-color: var(--main-color-2);
}

.training-selection form input::placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.training-selection h4 {
  margin-bottom: 0.5em;
}

.training-selection .participate button {
  width: 100%;
}

.nopadding {
  padding: 0 !important;
}

/*=================================================================
  SurveyJS
==================================================================*/

.sv_main {
  -webkit-box-shadow: 5px 10px 30px #383838;
  box-shadow: 5px 10px 30px #383838;
  letter-spacing: 0.025em;
}

.sv_main .sv-boolean__switch {
  background-color: var(--main-color-2);
}

.sv_main .sv-boolean__slider {
  background-color: white;
}

.sv_main .sv-boolean__label--disabled {
  color: #828282;
}

.sv_main hr {
  border-bottom: 1px solid #e7e7e7;
}

.sv_main input[type="button"],
.sv_main button {
  color: white;
  background-color: transparent;
  box-sizing: border-box;
  width: 180px;
  height: 44px;
  letter-spacing: 1px;
  font-weight: lighter;
  border: 1px solid var(--main-color-2) !important;
  border-radius: 8px !important;
  min-width: 100px;
  cursor: pointer;
  padding: 0 2em;
  transition: all 100ms ease-in-out;
  outline: none;
}

.sv_main input[type="button"]:hover,
.sv_main button:hover {
  background-color: var(--main-color-2);
}

.sv_main .sv_body {
  background-color: var(--main-bg-color-dark);
}

.sv_main .sv_progress {
  /* margin-bottom: 2em; */
}

.sv_main .sv_progress_bar {
  background-image: linear-gradient(to right, var(--main-color-1), var(--main-color-2));
}

.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
  border: 1px solid #4b180d;
  background-color: rgba(244, 79, 41, 0.15);
  color: white;
  font-size: 12px;
  border-radius: 8px;
}

.sv_main .sv-boolean__label {
  color: white !important;
}

.sv_main .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: var(--main-color-2);
  border-color: var(--main-color-2);
}

.sv_main .sv_q_rating_item .sv_q_rating_item_text {
  width: 30px;
  height: 30px;
  font-family: Ubuntu, sans-serif;
  font-size: 12px;
  font-weight: lighter;
  border: 1px solid var(--main-color-2);
  border-radius: 5px;
  margin-right: 0.5em;
  transition: all 0.1s ease-in-out;
}

.sv_main .sv_q_rating_item .sv_q_rating_item_text:hover {
  background-color: var(--main-color-2);
  border: 1px solid var(--main-color-2);
  border-radius: 5px;
}

.sv_main {
  position: relative;
  width: 100%;
  font-family: Ubuntu, sans-serif;
  font-size: 16px;
}

.sv_main .sv_container .sv_body {
  padding: 1em 1em 1.5em 1em;
}

.sv_main .sv_container .sv_body .sv_progress {
  height: 0.3em;
}

.sv_main .sv_container .sv_body .sv_progress .sv_progress_bar {
  height: 100%;
  min-width: 100px;
  border-radius: 1px;
  position: relative;
  margin-top: 2em;
  transition: width 0.5s ease-in-out;
}

.sv_progress > span {
  position: absolute;
  font-weight: lighter;
  font-size: 14px;
  top: 15px;
  right: 30px;
}

.sv_progress .sv_progress_bar > span {
  /* 
    Funktioniert nicht
    position: revert !important;  */
}

.sv_main .sv_container .sv_body .sv_p_root .sv_page_title {
  position: absolute;
  top: 10px;
  left: 35px;
  font-size: 1.25em;
  text-transform: uppercase;
  margin-top: 0.1em;
  font-weight: bold;
  letter-spacing: 1px;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
  line-height: 1.5em;
  font-weight: normal;
  font-size: 20px;
  margin: 0.5em 0;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_title span {
  hyphens: none;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_description {
  margin-top: 1em;
  min-height: 0.6em;
  margin-bottom: 1em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q {
  padding: 0.5em 1em 1.5em 1em;
  box-sizing: border-box;
  overflow: auto;
}

.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
  margin: 1em 0;
  padding: 1em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
  line-height: 2em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item {
  cursor: pointer;
  word-spacing: -0.3em;
  font-weight: normal;
  display: inline;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item > * {
  word-spacing: initial;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
  display: inline-block;
  min-width: 2em;
  padding: 0 0.3em;
  text-align: center;
  vertical-align: middle;
  line-height: 28px;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_min_text {
  margin-right: 1em;
  font-size: 12px;
  font-weight: lighter;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_max_text {
  margin-left: 1em;
  font-size: 12px;
  font-weight: lighter;
}

.sv_main .sv_container .sv_body .sv_nav {
  padding: 3em 1em;
  min-height: 3.5em;
}

.sv_main .sv_container .sv_body .sv_nav .sv_next_btn {
  outline: none;
  float: right;
}

.sv_main .sv_container .sv_body .sv_nav .sv_start_btn {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -20px);
}

.sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
  outline: none;
  background-color: var(--main-color-2);
  float: right;
}

.sv_main .sv_container .sv_body .sv_nav .sv_prev_btn {
  outline: none;
  float: left;
}

.sv_main .sv_container .sv_body .sv_row textarea {
  outline: none;
  background-color: var(--main-bg-color);
  border-radius: 4px;
  border: none;
  width: 100%;
  color: var(--main-color-white);
  padding: 8px;
}

.sv_main .sv_container .sv_body .sv_row .survey-description {
  padding-bottom: 1em;
  font-size: 18px;
}

.sv_main .sv_container .sv_body .sv_row #time-to-finish {
  margin-top: 1em;
}

.timer-progress {
  height: 4px;
  border-radius: 1px;
  width: var(--timer-progress-width);
  background-color: var(--main-color-2);
  transition: width 10ms ease;
}

@media (min-width: 320px) and (max-width: 480px) {
  .sv_main .sv_container .sv_body .sv_nav {
    padding: 3em 1em;
    min-height: 9em;
  }

  .sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
    margin-top: 0.5em;
    float: none;
    position: absolute;
    left: 50%;
    top: 92%;
    transform: translate(-50%, -100%);
  }

  .sv_main .sv_container .sv_body .sv_nav .sv_prev_btn {
    margin-bottom: 0.5em;
    float: none;
    position: absolute;
    left: 50%;
    margin-top: 4em;
    transform: translate(-50%, -100%);
  }

  .sv_main .sv_container .sv_body .sv_nav .sv_next_btn {
    margin-top: 0.5em;
    float: none;
    position: absolute;
    left: 50%;
    top: auto;
    transform: translate(-50%, -100%);
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_min_text {
    min-width: 100%;
    float: left;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_max_text {
    min-width: 100%;
    margin-left: 0;
    float: left;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    margin: 0 0;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
    margin: 0.5em 0;
  }
}

/* Radio Button Styling */

.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="radio"] {
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
  font-size: 1em;
  margin: -0.1em 0.55em 0 0;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="radio"]:focus {
  outline: none;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q input[type="radio"]:focus {
  outline: none;
}

.sv_main .sv_container input[type="radio"] {
  opacity: 0;
  margin-top: 1em;
}

.sv_main .sv_container .sv_q_radiogroup_label {
  font-size: 14px;
}

.sv_main .sv_container .sv_q_radiogroup_label:hover {
  cursor: pointer;
}

.sv_main .sv_container .sv_q_radiogroup_label span.check {
  position: relative;
  display: inline-block;
  padding-left: 15px;
}

.sv_main .sv_container .sv_q_radiogroup_label span.check::before,
.sv_main .sv_container .sv_q_radiogroup_label span.check::after {
  position: absolute;
  content: "";
}

.sv_main .sv_container .sv_q_radiogroup_label span.check::before {
  height: 18px;
  width: 18px;
  border: 1px solid #b9b9b9;
  border-radius: 50%;
  left: -18px;
  top: -15px;
}

.sv_main .sv_container .sv_q_radiogroup_label span.check::after {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: var(--main-color-2);
  left: -15px;
  top: -12px;
  -webkit-transition: all 0.1s ease;
}

.sv_main .sv_container input[type="radio"]:not(:checked) ~ span.check::after {
  transform-origin: center;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.sv_main .sv_container input[type="radio"]:checked ~ span.check::after {
  transform-origin: center;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Checkbox Styling */

.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="checkbox"] {
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
  font-size: 1em;
  margin: -0.1em 0.55em 0 0;
}

.sv_main .sv_container .sv_q_checkbox_label span.check {
  position: relative;
  display: inline-block;
  padding-left: 20px;
}

.sv_main .sv_container .sv_q_checkbox_label span.check::after {
  height: 11px;
  width: 11px;
  border-radius: 1px;
  background: var(--main-color-2);
  left: -14px;
  top: -11px;
  -webkit-transition: all 0.1s ease;
}

/* Hide the checkmark by default */
.sv_main .sv_container .sv_q_checkbox_label span.check::after {
  position: absolute;
  content: "";
  transform-origin: center;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

/* Unhide on the checked state */
.sv_main .sv_container .sv_q_checkbox_label input[type="checkbox"]:checked ~ span.check::after {
  position: absolute;
  transform-origin: center;
  content: "";
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sv_main .sv_container .checked .sv_q_checkbox_label span.check::after {
  transform-origin: center;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sv_main .sv_container .sv_q_checkbox_label span.check::before {
  height: 19px;
  width: 19px;
  border: 2px solid #b9b9b9;
  border-radius: 4px;
  left: -18px;
  top: -15px;
}

.sv_main .sv_container .sv_q_checkbox_label span.check::before {
  position: absolute;
  content: "";
}

.sv_main .sv_container .sv_q_checkbox_label:hover {
  cursor: pointer;
}

.sv_main .sv_container input[type="checkbox"] {
  opacity: 0;
  margin-top: 1em;
}

.sv_main .sv_container .sv_q_checkbox_label {
  font-size: 14px;
}

.schulungen {
  min-height: 700px;
}

/*=================================================================
  Admin Page
==================================================================*/

.schulungen .admin .previous-trainings .training {
  background-color: var(--main-bg-color-dark);
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-left: solid 3px var(--main-color-2);
  margin: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.schulungen .admin .interaction-container {
  margin-top: 1em;
  background-color: var(--main-bg-color-dark);
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  justify-content: center;
}

.schulungen .admin .interaction-containers {
  margin-top: 1em;
  background-color: var(--main-bg-color-dark);
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  display: flex;
}

.schulungen .admin .interaction-container h4 {
  margin-top: 0.5em;
}

.schulungen .admin .interaction-container h1 {
  text-align: center;
}

.schulungen .admin .interaction-container h5 {
  margin-bottom: 0;
}

.schulungen .admin .new-training {
  padding: 20px;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2em;
}

.schulungen .admin .new-training .line {
  height: 2px;
  background-color: var(--main-bg-color);
  border-radius: 50%;
  width: 50%;
  margin-top: 1em;
}

.schulungen .admin .new-training a {
  font-size: 14px;
}

.schulungen .admin .new-training a img {
  height: 25px;
  margin-left: 0.5em;
}

.schulungen .admin .new-training button {
  font-size: 14px;
}

.schulungen .admin .new-training button[disabled] {
  border-color: var(--main-bg-color);
}

.schulungen .admin .new-training button img {
  height: 25px;
  margin-left: 0.5em;
}

.schulungen .admin .new-training .type-selector {
  width: 60%;
}

.schulungen .admin .new-training .key {
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  border: 1px solid var(--main-color-2);
  background-color: var(--main-bg-color);
  color: var(--main-color-white);
  height: 50px;
  width: 60%;
  outline: none;
}

.schulungen .admin .new-training .key:placeholder-shown {
  border-color: var(--main-color-white);
}

.schulungen .admin .new-training .key:required:valid {
  border-color: var(--main-color-2);
}

.schulungen .admin .new-training .key:required:valid:focus {
  border-color: var(--main-color-2);
}

.schulungen .admin .new-training .key:not(:placeholder-shown):invalid {
  border: 1px solid #f44f29;
  background-color: rgba(244, 79, 41, 0.15);
  box-shadow: none;
  outline: none;
}

.schulungen .admin .new-training .key:focus {
  box-shadow: 1px 1px 30px var(--main-bg-color-dark);
  border-color: var(--main-color-2);
}

.schulungen .admin .new-training .key::placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 18px;
}

/*.schulungen .admin .new-training input[type="text"] {*/
/*    padding: 10px 10px 10px 10px;*/
/*    border-radius: 8px;*/
/*    border: 1px solid var(--main-color-2);*/
/*    background-color: var(--main-bg-color);*/
/*    color: var(--main-color-white);*/
/*    height: 50px;*/
/*    width: 60%;*/
/*    outline: none;*/
/*}*/

/*.schulungen .admin .new-training input[type="text"]:placeholder-shown {*/
/*    border-color: var(--main-color-white);*/
/*}*/

/*.schulungen .admin .new-training input[type="text"]:required:valid {*/
/*    border-color: var(--main-color-2);*/
/*}*/

/*.schulungen .admin .new-training input[type="text"]:required:valid:focus {*/
/*    border-color: var(--main-color-2);*/
/*}*/

/*.schulungen .admin .new-training input[type="text"]:not(:placeholder-shown):invalid {*/
/*    border: 1px solid #F44F29;*/
/*    background-color: rgba(244, 79, 41, 0.15);*/
/*    box-shadow: none;*/
/*    outline: none;*/
/*}*/

/*.schulungen .admin .new-training input[type="text"]:focus {*/
/*    box-shadow: 1px 1px 30px var(--main-bg-color-dark);*/
/*    border-color: var(--main-color-2);*/
/*}*/

/*.schulungen .admin .new-training input[type="text"]::placeholder {*/
/*    font-family: "Quattrocento Sans", sans-serif;*/
/*    font-weight: 100;*/
/*    color: #b3b3b3;*/
/*    font-size: 18px;*/
/*}*/

.schulungen .charts {
  padding: 15px;
}

.schulungen .charts img {
  object-fit: contain;
  height: 50px;
  margin-bottom: 2em;
}
.schulungen .charts .del-btn {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
}

.schulungen .charts .del-btn button {
  margin-left: auto;
}

.schulungen .charts .chartbox {
  text-align: left;
  padding: 15px;
  background-color: var(--main-bg-color-dark);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  color: white;
  margin-top: 30px;
}

.schulungen .charts .chartbox canvas {
  margin-top: 1em;
}

.schulungen .charts .accordion {
  text-align: left;
  padding: 15px;
  background-color: var(--main-bg-color-dark);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  color: white;
  margin-top: 30px;
}

.schulungen .charts .accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.schulungen .charts .accordion__button {
  color: white;
  cursor: pointer;
  padding: 10px 10px 10px 15px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-top: 0.5em;
}

.schulungen .charts .accordion__button:hover {
  background-color: var(--main-bg-color);
}

.schulungen .charts .accordion__button:before {
  display: inline-block;
  content: "";
  height: 8px;
  width: 8px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.schulungen .charts .accordion__button[aria-expanded="true"]::before,
.schulungen .charts .accordion__button[aria-selected="true"]::before {
  transform: rotate(45deg);
}

.schulungen .charts .accordion__panel {
  animation: fadein 0.25s ease-in;
}

.schulungen .charts .cancel {
  border: 2px solid var(--main-bg-color-dark);
  color: var(--main-color-white);
  cursor: not-allowed;
}

/* Accordion animation */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Legend */

.schulungen .charts .legend {
  background-color: var(--main-bg-color-dark);
  border-radius: 5px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  padding: 15px;
}

.schulungen .charts .legend > span {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
}

.schulungen .charts .legend .info-row {
  padding-left: 15px;
}

.schulungen .charts .legend .info {
  margin-bottom: 10px;
}

.schulungen .charts .legend .color-box {
  border-radius: 1px;
}

/*=================================================================
  question-selector
==================================================================*/

.schulungen .training .q-selector {
  margin-top: 2em;
}

/*=================================================================
  Scroll Button
==================================================================*/

.scroll-up-btn {
  outline: none;
  background-color: transparent;
}

.scroll-up-btn .up-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.25);
}

.scroll-up-btn .circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--main-color-2);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  outline: none;
}

.scroll-up-container {
  position: fixed;
  right: -200px;
  bottom: 50px;
  transition: right 0.5s ease-in-out;
  cursor: pointer;
  padding: 10px;
  outline: none;
  z-index: 99;
}

.scroll-up-transition {
  right: 20px;
}

/*=================================================================
  Login Form
==================================================================*/

.login-form h2 {
  margin-bottom: 0.5em;
  font-size: 30px;
}

.login-form form input {
  padding: 15px 15px 15px 20px;
  border-radius: 8px;
  border: 1px solid var(--main-color-2);
  background-color: var(--main-bg-color);
  color: var(--main-color-white);
  width: 300px;
  height: 40px;
  outline: none;
  margin-bottom: 1em;
}

.login-form form input:placeholder-shown {
  border-color: var(--main-color-white);
}

.login-form form input:required:valid {
  border-color: var(--main-color-2);
}

.login-form form input:required:valid:focus {
  border-color: var(--main-color-2);
}

.login-form form input:not(:placeholder-shown):invalid {
  border: 1px solid #f44f29;
  background-color: rgba(244, 79, 41, 0.15);
  box-shadow: none;
  outline: none;
}

.login-form form input:focus {
  box-shadow: 1px 1px 30px var(--main-bg-color-dark);
  border-color: var(--main-color-2);
}

.login-form form input::placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.login-form button {
  font-size: 16px;
  margin-top: 1em;
}

.login-form button img {
  height: 25px;
  margin-left: 1em;
}

.stats-container {
  display: flex;
}

.stats {
  background-color: var(--main-bg-color);
  border-radius: 4px;
  padding: 10px;
  margin: auto;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
}

/*=================================================================
  Language Selection
==================================================================*/

.lang-selector {
  padding-right: 0 !important;
  padding-left: 15px !important;
}

/*=================================================================
  Training complete
==================================================================*/

.training-complete button img {
  margin-left: 1em;
}

/*=================================================================
  Editor
==================================================================*/

.editor .creator {
  background-color: var(--main-bg-color-dark);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.editor .creator .settings img {
  width: 50px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.editor .button-row {
  margin: 1em 0 1em auto;
  display: block;
  float: right;
}

.editor .button-row button {
  margin-left: 1em;
}

.editor .button-row .cancel {
  border-color: var(--main-bg-color);
}

.editor .add-question {
  margin-top: 1em;
}

.editor .question-type img {
  height: 20px;
}

.editor .multi-choice {
  position: fixed;
  margin-top: 60px;
}

.editor .single-choice {
  position: fixed;
  margin-top: 120px;
}

.editor .bool-choice {
  position: fixed;
}

.editor .text-choice {
  position: fixed;
  margin-top: 180px;
}

.editor .question-type span {
  margin-left: 1em;
}

.editor .choices {
  display: flex;
  align-items: center;
}

.editor .choices button {
  background-color: transparent;
  border-radius: 4px;
  border: none;
  color: white;
  outline: none;
}

.editor .choices button img {
  height: 20px;
  transform: rotate(45deg);
}

.editor .expandable {
  width: 100%;
}

.editor .question-row {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--question-bar-color);
  padding: 5px 10px 5px;
  border-radius: 6px 6px 0 0;
}

.editor .editable-question {
  padding: 20px;
}

/*=================================================================
  Editor - Radiogroup
==================================================================*/

.editable-question input[type="text"] {
  margin-bottom: 0.25em;
  margin-top: 0.25em;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #858585;
  background-color: var(--main-bg-color);
  color: var(--main-color-white);
  height: 40px;
  outline: none;
}

.editable-question input[type="text"]:placeholder-shown {
  border-color: var(--main-color-white);
}

.editable-question input[type="text"]:focus {
  box-shadow: 1px 1px 30px var(--main-bg-color-dark);
  border-color: var(--main-color-2);
}

.editable-question input[type="text"]::placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.editable-question .radiogroup .add-btn {
  display: block;
  margin: 1em auto 0;
}

.editable-question .radiogroup .add-btn img {
  width: 50%;
}

.editor .arrow {
  position: absolute;
  right: 20px;
  transform: rotate(90deg);
  height: 25px;
}

.editable-question .radiogroup .titles {
  margin-top: 1em;
}

.editable-question .visible input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}

.editable-question .visible input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  background-color: var(--main-bg-color);
}

.editable-question .visible input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border: 0.025em solid var(--main-bg-color);
  border-radius: 2px;
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: var(--main-color-2);
}

.editable-question input[type="radio"] {
  position: relative;
  cursor: pointer;
}

.editable-question input[type="radio"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 50%;
  border: 1px solid #c1c1c1;
  border-radius: 50%;
  background-color: var(--main-bg-color);
  margin-left: -7px; /* to center the pseudo element within col-1 */
}

.editable-question input[type="radio"]:checked:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border: 0.025em solid var(--main-bg-color);
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: calc(50% - 5px); /* to center the pseudo element within col-1 */
  background-color: var(--main-color-2);
}

/*
 Checkboxes
*/

.editable-question .choices input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}

.editable-question .choices input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 50%;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  background-color: var(--main-bg-color);
  margin-left: -7px; /* to center the pseudo element within col-1 */
}

.editable-question .choices input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border: 0.025em solid var(--main-bg-color);
  border-radius: 2px;
  position: absolute;
  top: 2px;
  left: calc(50% - 5px); /* to center the pseudo element within col-1 */
  background-color: var(--main-color-2);
}

.editable-question .visible label {
  margin-left: 1em;
}

.editable-question .del-btn {
  display: flex;
  justify-content: flex-end;
}

.editable-question .del-btn button {
  background-color: transparent;
  border: none;
  outline: none;
}

.editable-question .del-btn img {
  height: 20px;
}

.language-switch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-switch img {
  display: block;
  margin: 0 auto;
  height: 25px;
}

/*.bool-switch {*/
/*    margin: 1em;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

.bool-switch .switch {
  width: 100%;
}

/*=================================================================
  Editor - Settings
==================================================================*/

.editor-settings {
  position: absolute;
  top: 100px;
  left: 200px;
  right: 200px;
  bottom: 50px;
  background-color: var(--main-bg-color);
  border-radius: 14px;
  outline: none;
  padding: 20px;
  transition: all 100ms ease-in-out;
}

.settings-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.editor-settings .intro {
  height: 200px !important;
}

.editor-settings input {
  margin-bottom: 0.25em;
  margin-top: 0.25em;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #858585;
  background-color: var(--main-bg-color);
  color: var(--main-color-white);
  height: 40px;
  outline: none;
  width: 100%;
}

.editor-settings input:placeholder-shown {
  border-color: var(--main-color-white);
}

.editor-settings input:focus {
  box-shadow: 1px 1px 30px var(--main-bg-color-dark);
  border-color: var(--main-color-2);
}

.editor-settings input::placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

/* Toast messages */

.ct-toast-success {
  border-left: 3px solid var(--main-color-2) !important;
  color: #ffffff !important;
  background-color: var(--main-bg-color-dark) !important;
}

.ct-toast-success svg path {
  fill: var(--main-color-2) !important;
}

.ct-toast-error {
  color: #ffffff !important;
  background-color: var(--main-bg-color-dark) !important;
}

.ct-toast-info {
  color: #ffffff !important;
  border-left: 3px solid var(--main-color-2) !important;
  background-color: var(--main-bg-color-dark) !important;
}

.ct-toast-info svg path {
  fill: var(--main-color-2) !important;
}

.training-questions-counter {
  -webkit-box-shadow: 5px 10px 30px #383838;
  box-shadow: 5px 10px 30px #383838;
  letter-spacing: 0.025em;
  background-color: var(--main-bg-color-dark) !important;
  padding: 1em 1em 0.5em 2em;
}

.training-timer-container {
  background-color: var(--main-bg-color-dark) !important;
}

.sv_q_num {
  display: none !important;
}
