@import url(https://fonts.googleapis.com/css?family=Anaheim|Quattrocento+Sans:400,700);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
html {
  width: 100%;
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
}

body {
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
  font-family: "Quattrocento Sans", sans-serif;
  color: #fff;
  color: var(--main-color-white);
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: "Ubuntu", sans-serif;
  color: #fff;
  color: var(--main-color-white);
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

p {
  font-family: "Quattrocento Sans", sans-serif;
}

[scroll-behavior="smooth"] {
  scroll-behavior: smooth;
}

/*=================================================================
  Color Setup
==================================================================*/

:root {
  --main-color-1: #a9f7e3;
  --main-color-2: #61bdaa;
  --main-bg-color: #4c4c4c;
  --main-bg-color-dark: #3f3d3d;
  --main-color-black: #000;
  --main-color-white: #fff;
  --question-bar-color: #858585;
  --timer-progress-width: 100%;
}

/*=================================================================
  Basic Setup
==================================================================*/

::selection {
  color: #fff;
  background: #61bdaa;
  background: var(--main-color-2);
}

a {
  color: #61bdaa;
  color: var(--main-color-2);
  -webkit-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

iframe {
  border: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus,
a:hover {
  color: #a9f7e3;
  color: var(--main-color-1);
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 100;
}

.loading-results {
  position: absolute;
  left: 50%;
  top: 80%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 100;
}

.btn {
  background-color: transparent;
  color: #fff;
  padding: 10px 30px;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.btn:focus {
  color: #ddd;
}

.btn-transparent {
  border-color: #61bdaa;
  border-color: var(--main-color-2);
  font-family: "Ubuntu", sans-serif;
  padding: 12px 30px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  border-radius: 8px;
  border-width: 2px;
}

.btn-startseite {
  padding: 12px 26px;
  margin-top: 1em;
}

.btn-prüfung {
  margin-left: 30em;
  margin-top: 2em;
}

.btn-modal {
  margin-top: 5em;
  float: right;
}

.btn-transparent:hover,
.btn-transparent:focus {
  background-color: #61bdaa;
  background-color: var(--main-color-2);
  border: 2px solid transparent;
  color: #fff;
}

.headline-border {
  border-top: 2px solid #61bdaa;
  border-top: 2px solid var(--main-color-2);
  height: 1px;
  margin: 0.2em auto 0;
  position: relative;
  width: 25%;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
}

.bg-one {
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
}

.bg-darker {
  background: #3f3d3d;
  background: var(--main-bg-color-dark);
}

.inline-block {
  display: inline-block;
}

figure {
  margin: 0;
}

a {
  -webkit-transition: all 0.2s ease-in 0s;
  transition: all 0.2s ease-in 0s;
}

iframe {
  border: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus,
a:hover {
  color: #a9f7e3;
  color: var(--main-color-1);
}

.btn-main {
  border-color: #61bdaa;
  border-color: var(--main-color-2);
  font-family: "Ubuntu", sans-serif;
  padding: 14px 36px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  border-radius: 8px;
  border-width: 2px;
}

.btn-main:hover {
  background: #61bdaa;
  background: var(--main-color-2);
  color: #fff;
}

section {
  padding: 100px 0;
}

@media (max-width: 480px) {
  section {
    padding: 50px 0;
  }
}

@media (max-width: 768px) {
  section {
    padding: 80px 0;
  }
}

.not-found img {
  height: 450px;
  margin-bottom: 3em;
}

.section-sm {
  padding: 70px 0;
}

.btn:focus {
  color: #ddd;
  box-shadow: none;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
}

.title {
  padding-bottom: 60px;
}

.title h2 {
  font-weight: 700;
  font-size: 48px;
  color: #fff;
  text-align: center;
}

@media (max-width: 374px) {
  .title h2 {
    font-size: 44px;
  }
}

@media (max-width: 767px) {
  .title h2 {
    font-size: 40px;
  }
}

.title h2 span {
  color: #61bdaa;
  color: var(--main-color-2);
}

.title p {
  color: #666;
}

.section-bg {
  background: #3f3d3d;
  background: var(--main-bg-color-dark);
  padding: 100px 0;
}

.overly {
  position: relative;
}

.overly:before {
  content: "";
  background: rgba(0, 0, 0, 0.096);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.logo {
  margin-top: 10px;
  display: inline-block;
}

/*=================================================================
  Footer section
==================================================================*/

.bg-black {
  background-color: #000;
  background-color: var(--main-color-black);
}

.copyright {
  padding: 20px 0;
  color: #fff;
}

.copyright img {
  margin-top: 2em;
  height: 4em;
  margin-bottom: 3em;
}

.copyright p {
  margin-top: 10px;
  font-size: 16px;
  text-transform: none;
  color: #fff;
  color: var(--main-color-white);
}

.copyright a {
  font-size: 20px;
  font-family: "Ubuntu", sans-serif;
  text-transform: uppercase;
  padding: 0 1em;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.copyright a:hover {
  color: #61bdaa;
  color: var(--main-color-2);
}

.ftext {
  margin-bottom: 4em;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .post-single-content {
    padding-left: 10px;
    padding-right: 10px;
  }

  .post-single-content p {
    width: 100%;
  }
}

.post-single-content {
  font-size: 16px;
  color: white;
  margin-top: 2em;
}

.post-single-content h2 {
  margin-bottom: 1em;
}

.navigation-shadow {
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
}

header {
  background-color: #3f3d3d;
  background-color: var(--main-bg-color-dark);
  width: 100%;
  margin-bottom: 0;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 10px;
  z-index: 100;
}

header .admin-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

header .admin-nav .admin-btn {
  margin-left: auto;
}

header .logo {
  vertical-align: middle;
  border-style: none;
  height: 3em;
}

/*=================================================================
  Logout Button
==================================================================*/
header .right-btn {
  padding-left: 0;
  flex-direction: row;
  margin-left: auto;
}

header .right-btn .logout {
  height: 50px;
}

header .right-btn .logout img {
  height: 25px;
  margin-left: 1em;
}

/*=================================================================
  Training Selection Boxes
==================================================================*/

.training-selection {
  background-color: #3f3d3d;
  background-color: var(--main-bg-color-dark);
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
  padding: 16px;
  margin-bottom: 2em;
  border-radius: 4px;
}

.training-selection img {
  margin-bottom: 1em;
}

.training-selection form .key {
  margin-bottom: 2em;
}

.training-selection form button {
  color: #fff;
  color: var(--main-color-white);
  margin-bottom: 2em;
}

.training-selection form button:disabled {
  border: 2px solid #000;
  border: 2px solid var(--main-color-black);
  color: #000;
  color: var(--main-color-black);
  cursor: not-allowed;
}

.training-selection form button:disabled:hover {
  border: 2px solid #000;
  border: 2px solid var(--main-color-black);
  background-color: transparent;
  color: #000;
  color: var(--main-color-black);
}

.training-selection form input {
  margin-top: 1em;
  padding: 15px 15px 15px 20px;
  border-radius: 8px;
  border: 1px solid #61bdaa;
  border: 1px solid var(--main-color-2);
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
  color: #fff;
  color: var(--main-color-white);
  height: 40px;
  width: 100%;
  outline: none;
}

.training-selection form input:placeholder-shown {
  border-color: #fff;
  border-color: var(--main-color-white);
}

.training-selection form input:required:valid {
  border-color: #61bdaa;
  border-color: var(--main-color-2);
}

.training-selection form input:required:valid:focus {
  border-color: #61bdaa;
  border-color: var(--main-color-2);
}

.training-selection form input:not(:placeholder-shown):invalid {
  border: 1px solid #f44f29;
  background-color: rgba(244, 79, 41, 0.15);
  box-shadow: none;
  outline: none;
}

.training-selection form input:focus {
  box-shadow: 1px 1px 30px #3f3d3d;
  box-shadow: 1px 1px 30px var(--main-bg-color-dark);
  border-color: #61bdaa;
  border-color: var(--main-color-2);
}

.training-selection form input::-webkit-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.training-selection form input:-ms-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.training-selection form input::-ms-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.training-selection form input::placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.training-selection h4 {
  margin-bottom: 0.5em;
}

.training-selection .participate button {
  width: 100%;
}

.nopadding {
  padding: 0 !important;
}

/*=================================================================
  SurveyJS
==================================================================*/

.sv_main {
  box-shadow: 5px 10px 30px #383838;
  letter-spacing: 0.025em;
}

.sv_main .sv-boolean__switch {
  background-color: #61bdaa;
  background-color: var(--main-color-2);
}

.sv_main .sv-boolean__slider {
  background-color: white;
}

.sv_main .sv-boolean__label--disabled {
  color: #828282;
}

.sv_main hr {
  border-bottom: 1px solid #e7e7e7;
}

.sv_main input[type="button"],
.sv_main button {
  color: white;
  background-color: transparent;
  box-sizing: border-box;
  width: 180px;
  height: 44px;
  letter-spacing: 1px;
  font-weight: lighter;
  border: 1px solid #61bdaa !important;
  border: 1px solid var(--main-color-2) !important;
  border-radius: 8px !important;
  min-width: 100px;
  cursor: pointer;
  padding: 0 2em;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  outline: none;
}

.sv_main input[type="button"]:hover,
.sv_main button:hover {
  background-color: #61bdaa;
  background-color: var(--main-color-2);
}

.sv_main .sv_body {
  background-color: #3f3d3d;
  background-color: var(--main-bg-color-dark);
}

.sv_main .sv_progress {
  /* margin-bottom: 2em; */
}

.sv_main .sv_progress_bar {
  background-image: -webkit-gradient(linear, left top, right top, from(#a9f7e3), to(#61bdaa));
  background-image: linear-gradient(to right, #a9f7e3, #61bdaa);
  background-image: -webkit-gradient(linear, left top, right top, from(var(--main-color-1)), to(var(--main-color-2)));
  background-image: linear-gradient(to right, var(--main-color-1), var(--main-color-2));
}

.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
  border: 1px solid #4b180d;
  background-color: rgba(244, 79, 41, 0.15);
  color: white;
  font-size: 12px;
  border-radius: 8px;
}

.sv_main .sv-boolean__label {
  color: white !important;
}

.sv_main .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: #61bdaa;
  background-color: var(--main-color-2);
  border-color: #61bdaa;
  border-color: var(--main-color-2);
}

.sv_main .sv_q_rating_item .sv_q_rating_item_text {
  width: 30px;
  height: 30px;
  font-family: Ubuntu, sans-serif;
  font-size: 12px;
  font-weight: lighter;
  border: 1px solid #61bdaa;
  border: 1px solid var(--main-color-2);
  border-radius: 5px;
  margin-right: 0.5em;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.sv_main .sv_q_rating_item .sv_q_rating_item_text:hover {
  background-color: #61bdaa;
  background-color: var(--main-color-2);
  border: 1px solid #61bdaa;
  border: 1px solid var(--main-color-2);
  border-radius: 5px;
}

.sv_main {
  position: relative;
  width: 100%;
  font-family: Ubuntu, sans-serif;
  font-size: 16px;
}

.sv_main .sv_container .sv_body {
  padding: 1em 1em 1.5em 1em;
}

.sv_main .sv_container .sv_body .sv_progress {
  height: 0.3em;
}

.sv_main .sv_container .sv_body .sv_progress .sv_progress_bar {
  height: 100%;
  min-width: 100px;
  border-radius: 1px;
  position: relative;
  margin-top: 2em;
  -webkit-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.sv_progress > span {
  position: absolute;
  font-weight: lighter;
  font-size: 14px;
  top: 15px;
  right: 30px;
}

.sv_progress .sv_progress_bar > span {
  /* 
    Funktioniert nicht
    position: revert !important;  */
}

.sv_main .sv_container .sv_body .sv_p_root .sv_page_title {
  position: absolute;
  top: 10px;
  left: 35px;
  font-size: 1.25em;
  text-transform: uppercase;
  margin-top: 0.1em;
  font-weight: bold;
  letter-spacing: 1px;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
  line-height: 1.5em;
  font-weight: normal;
  font-size: 20px;
  margin: 0.5em 0;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_title span {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_description {
  margin-top: 1em;
  min-height: 0.6em;
  margin-bottom: 1em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q {
  padding: 0.5em 1em 1.5em 1em;
  box-sizing: border-box;
  overflow: auto;
}

.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
  margin: 1em 0;
  padding: 1em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
  line-height: 2em;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item {
  cursor: pointer;
  word-spacing: -0.3em;
  font-weight: normal;
  display: inline;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item > * {
  word-spacing: normal;
  word-spacing: initial;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
  display: inline-block;
  min-width: 2em;
  padding: 0 0.3em;
  text-align: center;
  vertical-align: middle;
  line-height: 28px;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_min_text {
  margin-right: 1em;
  font-size: 12px;
  font-weight: lighter;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_max_text {
  margin-left: 1em;
  font-size: 12px;
  font-weight: lighter;
}

.sv_main .sv_container .sv_body .sv_nav {
  padding: 3em 1em;
  min-height: 3.5em;
}

.sv_main .sv_container .sv_body .sv_nav .sv_next_btn {
  outline: none;
  float: right;
}

.sv_main .sv_container .sv_body .sv_nav .sv_start_btn {
  position: absolute;
  left: 50%;
  bottom: 20px;
  -webkit-transform: translate(-50%, -20px);
          transform: translate(-50%, -20px);
}

.sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
  outline: none;
  background-color: #61bdaa;
  background-color: var(--main-color-2);
  float: right;
}

.sv_main .sv_container .sv_body .sv_nav .sv_prev_btn {
  outline: none;
  float: left;
}

.sv_main .sv_container .sv_body .sv_row textarea {
  outline: none;
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
  border-radius: 4px;
  border: none;
  width: 100%;
  color: #fff;
  color: var(--main-color-white);
  padding: 8px;
}

.sv_main .sv_container .sv_body .sv_row .survey-description {
  padding-bottom: 1em;
  font-size: 18px;
}

.sv_main .sv_container .sv_body .sv_row #time-to-finish {
  margin-top: 1em;
}

.timer-progress {
  height: 4px;
  border-radius: 1px;
  width: 100%;
  width: var(--timer-progress-width);
  background-color: #61bdaa;
  background-color: var(--main-color-2);
  -webkit-transition: width 10ms ease;
  transition: width 10ms ease;
}

@media (min-width: 320px) and (max-width: 480px) {
  .sv_main .sv_container .sv_body .sv_nav {
    padding: 3em 1em;
    min-height: 9em;
  }

  .sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
    margin-top: 0.5em;
    float: none;
    position: absolute;
    left: 50%;
    top: 92%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
  }

  .sv_main .sv_container .sv_body .sv_nav .sv_prev_btn {
    margin-bottom: 0.5em;
    float: none;
    position: absolute;
    left: 50%;
    margin-top: 4em;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
  }

  .sv_main .sv_container .sv_body .sv_nav .sv_next_btn {
    margin-top: 0.5em;
    float: none;
    position: absolute;
    left: 50%;
    top: auto;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_min_text {
    min-width: 100%;
    float: left;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_max_text {
    min-width: 100%;
    margin-left: 0;
    float: left;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    margin: 0 0;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
    margin: 0.5em 0;
  }
}

/* Radio Button Styling */

.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="radio"] {
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
  font-size: 1em;
  margin: -0.1em 0.55em 0 0;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="radio"]:focus {
  outline: none;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q input[type="radio"]:focus {
  outline: none;
}

.sv_main .sv_container input[type="radio"] {
  opacity: 0;
  margin-top: 1em;
}

.sv_main .sv_container .sv_q_radiogroup_label {
  font-size: 14px;
}

.sv_main .sv_container .sv_q_radiogroup_label:hover {
  cursor: pointer;
}

.sv_main .sv_container .sv_q_radiogroup_label span.check {
  position: relative;
  display: inline-block;
  padding-left: 15px;
}

.sv_main .sv_container .sv_q_radiogroup_label span.check::before,
.sv_main .sv_container .sv_q_radiogroup_label span.check::after {
  position: absolute;
  content: "";
}

.sv_main .sv_container .sv_q_radiogroup_label span.check::before {
  height: 18px;
  width: 18px;
  border: 1px solid #b9b9b9;
  border-radius: 50%;
  left: -18px;
  top: -15px;
}

.sv_main .sv_container .sv_q_radiogroup_label span.check::after {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #61bdaa;
  background: var(--main-color-2);
  left: -15px;
  top: -12px;
  -webkit-transition: all 0.1s ease;
}

.sv_main .sv_container input[type="radio"]:not(:checked) ~ span.check::after {
  -webkit-transform-origin: center;
          transform-origin: center;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.sv_main .sv_container input[type="radio"]:checked ~ span.check::after {
  -webkit-transform-origin: center;
          transform-origin: center;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Checkbox Styling */

.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="checkbox"] {
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
  font-size: 1em;
  margin: -0.1em 0.55em 0 0;
}

.sv_main .sv_container .sv_q_checkbox_label span.check {
  position: relative;
  display: inline-block;
  padding-left: 20px;
}

.sv_main .sv_container .sv_q_checkbox_label span.check::after {
  height: 11px;
  width: 11px;
  border-radius: 1px;
  background: #61bdaa;
  background: var(--main-color-2);
  left: -14px;
  top: -11px;
  -webkit-transition: all 0.1s ease;
}

/* Hide the checkmark by default */
.sv_main .sv_container .sv_q_checkbox_label span.check::after {
  position: absolute;
  content: "";
  -webkit-transform-origin: center;
          transform-origin: center;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

/* Unhide on the checked state */
.sv_main .sv_container .sv_q_checkbox_label input[type="checkbox"]:checked ~ span.check::after {
  position: absolute;
  -webkit-transform-origin: center;
          transform-origin: center;
  content: "";
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sv_main .sv_container .checked .sv_q_checkbox_label span.check::after {
  -webkit-transform-origin: center;
          transform-origin: center;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sv_main .sv_container .sv_q_checkbox_label span.check::before {
  height: 19px;
  width: 19px;
  border: 2px solid #b9b9b9;
  border-radius: 4px;
  left: -18px;
  top: -15px;
}

.sv_main .sv_container .sv_q_checkbox_label span.check::before {
  position: absolute;
  content: "";
}

.sv_main .sv_container .sv_q_checkbox_label:hover {
  cursor: pointer;
}

.sv_main .sv_container input[type="checkbox"] {
  opacity: 0;
  margin-top: 1em;
}

.sv_main .sv_container .sv_q_checkbox_label {
  font-size: 14px;
}

.schulungen {
  min-height: 700px;
}

/*=================================================================
  Admin Page
==================================================================*/

.schulungen .admin .previous-trainings .training {
  background-color: #3f3d3d;
  background-color: var(--main-bg-color-dark);
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-left: solid 3px #61bdaa;
  border-left: solid 3px var(--main-color-2);
  margin: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.schulungen .admin .interaction-container {
  margin-top: 1em;
  background-color: #3f3d3d;
  background-color: var(--main-bg-color-dark);
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  justify-content: center;
}

.schulungen .admin .interaction-containers {
  margin-top: 1em;
  background-color: #3f3d3d;
  background-color: var(--main-bg-color-dark);
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  display: flex;
}

.schulungen .admin .interaction-container h4 {
  margin-top: 0.5em;
}

.schulungen .admin .interaction-container h1 {
  text-align: center;
}

.schulungen .admin .interaction-container h5 {
  margin-bottom: 0;
}

.schulungen .admin .new-training {
  padding: 20px;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2em;
}

.schulungen .admin .new-training .line {
  height: 2px;
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
  border-radius: 50%;
  width: 50%;
  margin-top: 1em;
}

.schulungen .admin .new-training a {
  font-size: 14px;
}

.schulungen .admin .new-training a img {
  height: 25px;
  margin-left: 0.5em;
}

.schulungen .admin .new-training button {
  font-size: 14px;
}

.schulungen .admin .new-training button[disabled] {
  border-color: #4c4c4c;
  border-color: var(--main-bg-color);
}

.schulungen .admin .new-training button img {
  height: 25px;
  margin-left: 0.5em;
}

.schulungen .admin .new-training .type-selector {
  width: 60%;
}

.schulungen .admin .new-training .key {
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  border: 1px solid #61bdaa;
  border: 1px solid var(--main-color-2);
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
  color: #fff;
  color: var(--main-color-white);
  height: 50px;
  width: 60%;
  outline: none;
}

.schulungen .admin .new-training .key:placeholder-shown {
  border-color: #fff;
  border-color: var(--main-color-white);
}

.schulungen .admin .new-training .key:required:valid {
  border-color: #61bdaa;
  border-color: var(--main-color-2);
}

.schulungen .admin .new-training .key:required:valid:focus {
  border-color: #61bdaa;
  border-color: var(--main-color-2);
}

.schulungen .admin .new-training .key:not(:placeholder-shown):invalid {
  border: 1px solid #f44f29;
  background-color: rgba(244, 79, 41, 0.15);
  box-shadow: none;
  outline: none;
}

.schulungen .admin .new-training .key:focus {
  box-shadow: 1px 1px 30px #3f3d3d;
  box-shadow: 1px 1px 30px var(--main-bg-color-dark);
  border-color: #61bdaa;
  border-color: var(--main-color-2);
}

.schulungen .admin .new-training .key::-webkit-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 18px;
}

.schulungen .admin .new-training .key:-ms-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 18px;
}

.schulungen .admin .new-training .key::-ms-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 18px;
}

.schulungen .admin .new-training .key::placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 18px;
}

/*.schulungen .admin .new-training input[type="text"] {*/
/*    padding: 10px 10px 10px 10px;*/
/*    border-radius: 8px;*/
/*    border: 1px solid var(--main-color-2);*/
/*    background-color: var(--main-bg-color);*/
/*    color: var(--main-color-white);*/
/*    height: 50px;*/
/*    width: 60%;*/
/*    outline: none;*/
/*}*/

/*.schulungen .admin .new-training input[type="text"]:placeholder-shown {*/
/*    border-color: var(--main-color-white);*/
/*}*/

/*.schulungen .admin .new-training input[type="text"]:required:valid {*/
/*    border-color: var(--main-color-2);*/
/*}*/

/*.schulungen .admin .new-training input[type="text"]:required:valid:focus {*/
/*    border-color: var(--main-color-2);*/
/*}*/

/*.schulungen .admin .new-training input[type="text"]:not(:placeholder-shown):invalid {*/
/*    border: 1px solid #F44F29;*/
/*    background-color: rgba(244, 79, 41, 0.15);*/
/*    box-shadow: none;*/
/*    outline: none;*/
/*}*/

/*.schulungen .admin .new-training input[type="text"]:focus {*/
/*    box-shadow: 1px 1px 30px var(--main-bg-color-dark);*/
/*    border-color: var(--main-color-2);*/
/*}*/

/*.schulungen .admin .new-training input[type="text"]::placeholder {*/
/*    font-family: "Quattrocento Sans", sans-serif;*/
/*    font-weight: 100;*/
/*    color: #b3b3b3;*/
/*    font-size: 18px;*/
/*}*/

.schulungen .charts {
  padding: 15px;
}

.schulungen .charts img {
  object-fit: contain;
  height: 50px;
  margin-bottom: 2em;
}
.schulungen .charts .del-btn {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
}

.schulungen .charts .del-btn button {
  margin-left: auto;
}

.schulungen .charts .chartbox {
  text-align: left;
  padding: 15px;
  background-color: #3f3d3d;
  background-color: var(--main-bg-color-dark);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  color: white;
  margin-top: 30px;
}

.schulungen .charts .chartbox canvas {
  margin-top: 1em;
}

.schulungen .charts .accordion {
  text-align: left;
  padding: 15px;
  background-color: #3f3d3d;
  background-color: var(--main-bg-color-dark);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  color: white;
  margin-top: 30px;
}

.schulungen .charts .accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.schulungen .charts .accordion__button {
  color: white;
  cursor: pointer;
  padding: 10px 10px 10px 15px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-top: 0.5em;
}

.schulungen .charts .accordion__button:hover {
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
}

.schulungen .charts .accordion__button:before {
  display: inline-block;
  content: "";
  height: 8px;
  width: 8px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.schulungen .charts .accordion__button[aria-expanded="true"]::before,
.schulungen .charts .accordion__button[aria-selected="true"]::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.schulungen .charts .accordion__panel {
  -webkit-animation: fadein 0.25s ease-in;
          animation: fadein 0.25s ease-in;
}

.schulungen .charts .cancel {
  border: 2px solid #3f3d3d;
  border: 2px solid var(--main-bg-color-dark);
  color: #fff;
  color: var(--main-color-white);
  cursor: not-allowed;
}

/* Accordion animation */

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Legend */

.schulungen .charts .legend {
  background-color: #3f3d3d;
  background-color: var(--main-bg-color-dark);
  border-radius: 5px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  padding: 15px;
}

.schulungen .charts .legend > span {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
}

.schulungen .charts .legend .info-row {
  padding-left: 15px;
}

.schulungen .charts .legend .info {
  margin-bottom: 10px;
}

.schulungen .charts .legend .color-box {
  border-radius: 1px;
}

/*=================================================================
  question-selector
==================================================================*/

.schulungen .training .q-selector {
  margin-top: 2em;
}

/*=================================================================
  Scroll Button
==================================================================*/

.scroll-up-btn {
  outline: none;
  background-color: transparent;
}

.scroll-up-btn .up-img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0.25);
          transform: translate(-50%, -50%) scale(0.25);
}

.scroll-up-btn .circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #61bdaa;
  background-color: var(--main-color-2);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  outline: none;
}

.scroll-up-container {
  position: fixed;
  right: -200px;
  bottom: 50px;
  -webkit-transition: right 0.5s ease-in-out;
  transition: right 0.5s ease-in-out;
  cursor: pointer;
  padding: 10px;
  outline: none;
  z-index: 99;
}

.scroll-up-transition {
  right: 20px;
}

/*=================================================================
  Login Form
==================================================================*/

.login-form h2 {
  margin-bottom: 0.5em;
  font-size: 30px;
}

.login-form form input {
  padding: 15px 15px 15px 20px;
  border-radius: 8px;
  border: 1px solid #61bdaa;
  border: 1px solid var(--main-color-2);
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
  color: #fff;
  color: var(--main-color-white);
  width: 300px;
  height: 40px;
  outline: none;
  margin-bottom: 1em;
}

.login-form form input:placeholder-shown {
  border-color: #fff;
  border-color: var(--main-color-white);
}

.login-form form input:required:valid {
  border-color: #61bdaa;
  border-color: var(--main-color-2);
}

.login-form form input:required:valid:focus {
  border-color: #61bdaa;
  border-color: var(--main-color-2);
}

.login-form form input:not(:placeholder-shown):invalid {
  border: 1px solid #f44f29;
  background-color: rgba(244, 79, 41, 0.15);
  box-shadow: none;
  outline: none;
}

.login-form form input:focus {
  box-shadow: 1px 1px 30px #3f3d3d;
  box-shadow: 1px 1px 30px var(--main-bg-color-dark);
  border-color: #61bdaa;
  border-color: var(--main-color-2);
}

.login-form form input::-webkit-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.login-form form input:-ms-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.login-form form input::-ms-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.login-form form input::placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.login-form button {
  font-size: 16px;
  margin-top: 1em;
}

.login-form button img {
  height: 25px;
  margin-left: 1em;
}

.stats-container {
  display: flex;
}

.stats {
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
  border-radius: 4px;
  padding: 10px;
  margin: auto;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
}

/*=================================================================
  Language Selection
==================================================================*/

.lang-selector {
  padding-right: 0 !important;
  padding-left: 15px !important;
}

/*=================================================================
  Training complete
==================================================================*/

.training-complete button img {
  margin-left: 1em;
}

/*=================================================================
  Editor
==================================================================*/

.editor .creator {
  background-color: #3f3d3d;
  background-color: var(--main-bg-color-dark);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.editor .creator .settings img {
  width: 50px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.editor .button-row {
  margin: 1em 0 1em auto;
  display: block;
  float: right;
}

.editor .button-row button {
  margin-left: 1em;
}

.editor .button-row .cancel {
  border-color: #4c4c4c;
  border-color: var(--main-bg-color);
}

.editor .add-question {
  margin-top: 1em;
}

.editor .question-type img {
  height: 20px;
}

.editor .multi-choice {
  position: fixed;
  margin-top: 60px;
}

.editor .single-choice {
  position: fixed;
  margin-top: 120px;
}

.editor .bool-choice {
  position: fixed;
}

.editor .text-choice {
  position: fixed;
  margin-top: 180px;
}

.editor .question-type span {
  margin-left: 1em;
}

.editor .choices {
  display: flex;
  align-items: center;
}

.editor .choices button {
  background-color: transparent;
  border-radius: 4px;
  border: none;
  color: white;
  outline: none;
}

.editor .choices button img {
  height: 20px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.editor .expandable {
  width: 100%;
}

.editor .question-row {
  display: flex;
  flex-wrap: wrap;
  background-color: #858585;
  background-color: var(--question-bar-color);
  padding: 5px 10px 5px;
  border-radius: 6px 6px 0 0;
}

.editor .editable-question {
  padding: 20px;
}

/*=================================================================
  Editor - Radiogroup
==================================================================*/

.editable-question input[type="text"] {
  margin-bottom: 0.25em;
  margin-top: 0.25em;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #858585;
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
  color: #fff;
  color: var(--main-color-white);
  height: 40px;
  outline: none;
}

.editable-question input[type="text"]:placeholder-shown {
  border-color: #fff;
  border-color: var(--main-color-white);
}

.editable-question input[type="text"]:focus {
  box-shadow: 1px 1px 30px #3f3d3d;
  box-shadow: 1px 1px 30px var(--main-bg-color-dark);
  border-color: #61bdaa;
  border-color: var(--main-color-2);
}

.editable-question input[type="text"]::-webkit-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.editable-question input[type="text"]:-ms-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.editable-question input[type="text"]::-ms-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.editable-question input[type="text"]::placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.editable-question .radiogroup .add-btn {
  display: block;
  margin: 1em auto 0;
}

.editable-question .radiogroup .add-btn img {
  width: 50%;
}

.editor .arrow {
  position: absolute;
  right: 20px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  height: 25px;
}

.editable-question .radiogroup .titles {
  margin-top: 1em;
}

.editable-question .visible input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}

.editable-question .visible input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
}

.editable-question .visible input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border: 0.025em solid #4c4c4c;
  border: 0.025em solid var(--main-bg-color);
  border-radius: 2px;
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: #61bdaa;
  background-color: var(--main-color-2);
}

.editable-question input[type="radio"] {
  position: relative;
  cursor: pointer;
}

.editable-question input[type="radio"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 50%;
  border: 1px solid #c1c1c1;
  border-radius: 50%;
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
  margin-left: -7px; /* to center the pseudo element within col-1 */
}

.editable-question input[type="radio"]:checked:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border: 0.025em solid #4c4c4c;
  border: 0.025em solid var(--main-bg-color);
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: calc(50% - 5px); /* to center the pseudo element within col-1 */
  background-color: #61bdaa;
  background-color: var(--main-color-2);
}

/*
 Checkboxes
*/

.editable-question .choices input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}

.editable-question .choices input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 50%;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
  margin-left: -7px; /* to center the pseudo element within col-1 */
}

.editable-question .choices input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border: 0.025em solid #4c4c4c;
  border: 0.025em solid var(--main-bg-color);
  border-radius: 2px;
  position: absolute;
  top: 2px;
  left: calc(50% - 5px); /* to center the pseudo element within col-1 */
  background-color: #61bdaa;
  background-color: var(--main-color-2);
}

.editable-question .visible label {
  margin-left: 1em;
}

.editable-question .del-btn {
  display: flex;
  justify-content: flex-end;
}

.editable-question .del-btn button {
  background-color: transparent;
  border: none;
  outline: none;
}

.editable-question .del-btn img {
  height: 20px;
}

.language-switch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-switch img {
  display: block;
  margin: 0 auto;
  height: 25px;
}

/*.bool-switch {*/
/*    margin: 1em;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

.bool-switch .switch {
  width: 100%;
}

/*=================================================================
  Editor - Settings
==================================================================*/

.editor-settings {
  position: absolute;
  top: 100px;
  left: 200px;
  right: 200px;
  bottom: 50px;
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
  border-radius: 14px;
  outline: none;
  padding: 20px;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

.settings-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.editor-settings .intro {
  height: 200px !important;
}

.editor-settings input {
  margin-bottom: 0.25em;
  margin-top: 0.25em;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #858585;
  background-color: #4c4c4c;
  background-color: var(--main-bg-color);
  color: #fff;
  color: var(--main-color-white);
  height: 40px;
  outline: none;
  width: 100%;
}

.editor-settings input:placeholder-shown {
  border-color: #fff;
  border-color: var(--main-color-white);
}

.editor-settings input:focus {
  box-shadow: 1px 1px 30px #3f3d3d;
  box-shadow: 1px 1px 30px var(--main-bg-color-dark);
  border-color: #61bdaa;
  border-color: var(--main-color-2);
}

.editor-settings input::-webkit-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.editor-settings input:-ms-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.editor-settings input::-ms-input-placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

.editor-settings input::placeholder {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 100;
  color: #b3b3b3;
  font-size: 16px;
}

/* Toast messages */

.ct-toast-success {
  border-left: 3px solid #61bdaa !important;
  border-left: 3px solid var(--main-color-2) !important;
  color: #ffffff !important;
  background-color: #3f3d3d !important;
  background-color: var(--main-bg-color-dark) !important;
}

.ct-toast-success svg path {
  fill: #61bdaa !important;
  fill: var(--main-color-2) !important;
}

.ct-toast-error {
  color: #ffffff !important;
  background-color: #3f3d3d !important;
  background-color: var(--main-bg-color-dark) !important;
}

.ct-toast-info {
  color: #ffffff !important;
  border-left: 3px solid #61bdaa !important;
  border-left: 3px solid var(--main-color-2) !important;
  background-color: #3f3d3d !important;
  background-color: var(--main-bg-color-dark) !important;
}

.ct-toast-info svg path {
  fill: #61bdaa !important;
  fill: var(--main-color-2) !important;
}

.training-questions-counter {
  box-shadow: 5px 10px 30px #383838;
  letter-spacing: 0.025em;
  background-color: #3f3d3d !important;
  background-color: var(--main-bg-color-dark) !important;
  padding: 1em 1em 0.5em 2em;
}

.training-timer-container {
  background-color: #3f3d3d !important;
  background-color: var(--main-bg-color-dark) !important;
}

.sv_q_num {
  display: none !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: var(--main-bg-color);
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: var(--main-bg-color);
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: var(--main-bg-color);
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: var(--main-bg-color);
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: var(--main-bg-color);
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
}

.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: var(--main-bg-color-dark);
    color: white;
    border: 1px solid var(--main-bg-color);
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
    border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 0.3rem;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
    left: auto;
    right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
    margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
    left: auto;
    right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
    margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
    left: 42px;
    right: auto;
}

.react-datepicker__header {
    text-align: center;
    background-color: var(--main-bg-color);
    border-bottom: 1px solid var(--main-bg-color-dark);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
}

.react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: white;
    font-weight: bold;
    font-size: 0.944rem;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
}

.react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 80px;
}

.react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
    border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
    float: left;
}

.react-datepicker__year {
    margin: 0.4rem;
    text-align: center;
}

.react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
}

.react-datepicker__year .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
}

.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
}

.react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
    display: inline-block;
    margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
    width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
    -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
    margin-left: 5px;
    display: inline-block;
}

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 85px;
}

.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 85px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #216ba5;
    color: white;
    font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
}

.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: white;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
    background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
    color: #ccc;
    pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
    cursor: default;
    background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
    font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
    background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
    color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
    color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: var(--main-color-2);
    color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: var(--main-color-2);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: var(--main-color-2);
    color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: var(--main-color-2);
}

.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range ,
.react-datepicker__quarter-text--in-selecting-range ,
.react-datepicker__year-text--in-selecting-range {
    background-color: var(--main-color-2);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__quarter-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__year-text--in-range {
    background-color: #f0f0f0;
    color: white;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
    background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    background-color: #216ba5;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
    background-color: #f0f0f0;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.react-datepicker__input-container input[type="text"] {
    padding: 10px 10px 10px 10px;
    border-radius: 8px;
    border: 1px solid var(--main-color-2);
    background-color: var(--main-bg-color);
    color: var(--main-color-white);
    height: 50px;
    outline: none;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
    cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
    background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
    border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
}

.react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0px 6px 0px 0px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: var(--main-color-2);
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\00d7";
}

.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}

